import React from 'react';
import './DomainSearch.css';

const DomainSearch = () => {
  return (
    <div>
      <div className="hero-banner bg2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="inner-content text-center">
              <h6 className="style m-auto">Find Domain</h6>
              <h1 className="mt-3">let's find domain for <span className="highlight">Registration</span></h1>
              <p className="mt-3">Your domain helps people find you online and makes a first impression. Find one that represents you perfectly.</p>
              <p><b>DOMIAN PRICE IS NOT FIXED THEY INCREASE AND DECREASE ACCORDING TO OFFER PRICE</b></p>
            </div>
            <form className="mt-4" method="post" action="https://manage.hostingaviator.cloud/cart.php?a=add&domain=register">
              <div className="domain-search-box">
                <input type="search" name="domain" placeholder="Find Your Domain Name" />
                <button type="submit">
                  <span className="d-md-inline">Search</span>
                </button>
              </div>
            </form>
            <div className="row justify-content-center">
              <div className="col-lg-10 col-12">
                <div className="theme-section-heading domain-register-box-bottom-img g-4">
                  <div className="domain-register-box-card">
                    <span className="domain-name">.com</span>
                    <span className="price d-block">Rs-1300</span>
                  </div>
                  <div className="domain-register-box-card">
                    <span className="domain-name">.in</span>
                    <span className="price d-block">Rs-700</span>
                  </div>
                  <div className="domain-register-box-card">
                    <span className="domain-name">.Store</span>
                    <span className="price d-block">Rs-500</span>
                  </div>
                  <div className="domain-register-box-card">
                    <span className="domain-name">.info</span>
                    <span className="price d-block">Rs-111.65</span>
                  </div>
                  <div className="domain-register-box-card">
                    <span className="domain-name">.host</span>
                    <span className="price d-block">Rs-900</span>
                  </div>
                  <div className="domain-register-box-card">
                    <span className="domain-name">.tech</span>
                    <span className="price d-block">Rs-540.32</span>
                  </div>
                  <div className="domain-register-box-card">
                    <span className="domain-name">.online</span>
                    <span className="price d-block">Rs-870</span>
                  </div>
                  <div className="domain-register-box-card">
                    <span className="domain-name">.org</span>
                    <span className="price d-block">Rs-810.90</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main>
        <section className="theme-section-gap bg-img-one">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-12">
                <div className="theme-section-heading text-center">
                  <h6 className="style m-auto">Coming Soon</h6>
                  <h2 className="mt-3 text-white">Thousands Of <span className="highlight">Domains</span> Are On Their Way</h2>
                  <p className="text-white">Pre-register now to get the domain of your choice</p>
                  <div className="text-white text-center m-auto justify-content-center d-flex mt-3">
                    <a className="theme-btn two" href="domain-promos">Learn More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="theme-section-gap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="theme-section-heading center">
                <h2>FREE Add-ons with every <span className="highlight">Domain</span> Name!</h2>
              </div>
            </div>
            <div className="row justify-content-between g-4 align-item-stretch mt-4">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="style-one-card align-items-start">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-open" viewBox="0 0 16 16">
                      <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882l-6-3.2ZM15 7.383l-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2Z"></path>
                    </svg>
                  </div>
                  <div className="theme-section-heading">
                    <h3>Free <span className="highlight">Email</span> Account</h3>
                    <p>Receive 2 personalized Email Addresses such as mail@yourdomain.com with free fraud, spam and virus protection.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="style-one-card align-items-start">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
                      <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81-.03-1.577-.13-2.282-.287z"></path>
                    </svg>
                  </div>
                  <div className="theme-section-heading">
                    <h3>Free <span className="highlight">Domain Forwarding</span></h3>
                    <p>Set up Domain Forwarding to redirect visitors to another website for free.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="style-one-card align-items-start">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shield-lock" viewBox="0 0 16 16">
                      <path d="M8 0c-.545 0-1 .318-1 .711v2.9C4.29 4.973 2 6.443 2 8.83c0 2.539 2.13 4.678 5.272 5.134V15h5.457v-.038c3.14-.46 5.272-2.594 5.272-5.134 0-2.387-2.29-3.857-5-5.219V.71C9 .318 8.545 0 8 0zM6.79 6.006c.42 0 .75.373.75.834v1.926a.835.835 0 1 1-1.667 0V6.84c0-.461.329-.834.834-.834zM8 1.774c-.143 0-.271.114-.271.256v1.714a1.617 1.617 0 0 1 1.087-.401V2.03c0-.142-.128-.256-.272-.256zM7.5 12.94V9.436c-.495.456-.91 1.187-.91 2.14 0 1.35.515 2.572 1.424 3.445.57-.932.808-1.979.808-3.13a3.447 3.447 0 0 0-.897-2.141zM8.497 3.457c.156-.374.439-.631.773-.731.147-.46.465-.803.874-.975-.119.25-.217.508-.308.76.053-.206-.103-.334-.23-.412-.349-.316-.524-.737-.6-1.18-.015.208-.092.376-.146.575-.177-.004-.307-.02-.372-.165.046.401-.106.784-.449.916.159.33-.164.642-.513.591z"></path>
                    </svg>
                  </div>
                  <div className="theme-section-heading">
                    <h3>Free <span className="highlight">DNS Management</span></h3>
                    <p>Manage your DNS settings for complete control of your domain.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="style-one-card align-items-start">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shield-lock" viewBox="0 0 16 16">
                      <path d="M8 0c-.545 0-1 .318-1 .711v2.9C4.29 4.973 2 6.443 2 8.83c0 2.539 2.13 4.678 5.272 5.134V15h5.457v-.038c3.14-.46 5.272-2.594 5.272-5.134 0-2.387-2.29-3.857-5-5.219V.71C9 .318 8.545 0 8 0zM6.79 6.006c.42 0 .75.373.75.834v1.926a.835.835 0 1 1-1.667 0V6.84c0-.461.329-.834.834-.834zM8 1.774c-.143 0-.271.114-.271.256v1.714a1.617 1.617 0 0 1 1.087-.401V2.03c0-.142-.128-.256-.272-.256zM7.5 12.94V9.436c-.495.456-.91 1.187-.91 2.14 0 1.35.515 2.572 1.424 3.445.57-.932.808-1.979.808-3.13a3.447 3.447 0 0 0-.897-2.141zM8.497 3.457c.156-.374.439-.631.773-.731.147-.46.465-.803.874-.975-.119.25-.217.508-.308.76.053-.206-.103-.334-.23-.412-.349-.316-.524-.737-.6-1.18-.015.208-.092.376-.146.575-.177-.004-.307-.02-.372-.165.046.401-.106.784-.449.916.159.33-.164.642-.513.591z"></path>
                    </svg>
                  </div>
                  <div className="theme-section-heading">
                    <h3>Free <span className="highlight">Domain Theft Protection</span></h3>
                    <p>Protect your domain with free domain theft protection.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default DomainSearch;
