import React from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './homme.css';
import logo from '../assets/images/fast.svg';
import wordpressImg from '../assets/images/wordpress.png';
import zencartImg from '../assets/images/zencart.png';
import phplistImg from '../assets/images/phplist.png';
import phpbbImg from '../assets/images/phpbb.png';
import osticketImg from '../assets/images/osticket.png';
import joomlaImg from '../assets/images/joomla.png';
import drupalImg from '../assets/images/drupal.png';
import coppermineImg from '../assets/images/coppermine.png';
import feature from '../assets/images/herobannerimg.png';
import performance from '../assets/images/performance.svg';
import customerImg from '../assets/images/customer.svg';
import guaranteeImg from '../assets/images/guarantee.svg';
import discountImg from '../assets/images/discount.svg';
import moneyImg from '../assets/images/money.svg';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="HOSTING AVIATOR - India's Best & Affordable Web Hosting & Domain Provider" />
        <meta property="og:description" content="Experience blazing-fast speeds and unparalleled reliability with zero downtime. Elevate your online presence today with Hosting Aviator!" />
        <meta property="og:image" content="URL_of_the_image_to_use" />
        <meta property="og:url" content="https://www.hostingaviator.cloud" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="home-page">
        <div className="hero-banner bg1">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6">
                <div className="inner-content">
                  <h6 className="style">Reliable hosting to make any website</h6>
                  <h1>Reliable hosting to make any <span className="highlight thirty">Website</span></h1>
                  <ul className="banner-list mt-3 mb-3">
                    <li>Unmetered Sub Domains, Accounts</li>
                    <li>99% Uptime Guarantee</li>
                    <li>07-Day Money-Back Guarantee</li>
                  </ul>
                  <p className="big mb-3">Get all the essentials features Starting at Rs-49</p>
                  <a className="theme-btn three width-increase" href="linux-hosting"><i className="ri-eye-line start" /> Get Started Now</a>
                </div>
              </div>
              <div className="col-lg-5 d-none d-lg-block">
                <img src={logo} alt="Fast Hosting Logo" />
              </div>
            </div>
          </div>
        </div>

        <div className="hero-banner-bottom-slider">
          <div className="container">
            <div className="row">
              <div className="image-slider">
                <div className="slider-track">
                  {[
                    { src: wordpressImg, alt: "WordPress" },
                    { src: zencartImg, alt: "Zen Cart" },
                    { src: phplistImg, alt: "PHP List" },
                    { src: phpbbImg, alt: "PHP BB" },
                    { src: osticketImg, alt: "OS Ticket" },
                    { src: wordpressImg, alt: "Lime Survey" },
                    { src: joomlaImg, alt: "Joomla" },
                    { src: drupalImg, alt: "Drupal" },
                    { src: coppermineImg, alt: "Coppermine" },
                  ].map((image, index) => (
                    <div className="slides text-center d-block" key={index}>
                      <img src={image.src} alt={image.alt} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <main>
          <section className="theme-section-gap">
            <div className="container">
              <div className="row justify-content-center">
                <div className="theme-section-heading text-center">
                  <h2>Search Most Popular <span className="highlight heading">Domain</span></h2>
                  <p>The perfect domain lets people know at a glance why you’re online (and why you’re awesome)</p>
                  <p><b>DOMIAN PRICE IS NOT FIXED THEY INCREASE AND DECREASE ACCORDING TO OFFER PRICE</b></p>
                </div>
              </div>
              <div className="row justify-content-between align-item-stretch g-4 mt-4">
                {[
                  { domain: ".In", price: "230.00" },
                  { domain: ".Store", price: "330.00" },
                  { domain: ".Com", price: "410.00" },
                  { domain: ".Net", price: "560.00" },
                ].map((item, index) => (
                  <div className="col-lg-3 col-sm-6 col-12" key={index}>
                    <div className="popular-domains text-center">
                      <h4>{item.domain}</h4>
                      <div className="content">
                        <span>Starting From</span>
                        <h2><sup>Rs</sup> <span className="highlight">{item.price}</span> /mo</h2>
                        <a href="domain-register" className="theme-btn three width-increase text-center btn-small">Check Now</a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className="theme-section-gap">
            <div className="container">
              <div className="row justify-content-center">
                <div className="theme-section-heading center">
                  <h2>FREE Add-ons with every <span className="highlight">Domain</span> Name!</h2>
                </div>
              </div>
              <div className="row justify-content-between g-4 align-item-stretch mt-4">
                {[
                  {
                    icon: "bi-envelope-open",
                    title: "Free Email Account",
                    description: "Receive 2 personalized Email Addresses such as mail@yourdomain.com with free fraud, spam and virus protection."
                  },
                  {
                    icon: "bi-globe2",
                    title: "Domain Forwarding",
                    description: "Point your domain name to another website for free! Redirect users when they type your domain name into a browser (with/without domain masking & SEO)."
                  },
                  {
                    icon: "bi-tv",
                    title: "DNS Management",
                    description: "Free lifetime DNS service which allows you to manage your DNS records on our globally distributed and highly redundant DNS infrastructure."
                  },
                  {
                    icon: "bi-globe-americas",
                    title: "Domain Theft Protection",
                    description: "Protect your Domain from being transferred out accidentally or without your permission with our free Domain Theft Protection."
                  },
                  {
                    icon: "bi-inboxes",
                    title: "Free Mail Forwards",
                    description: "Create free email forwards and automatically redirect your email to existing email accounts."
                  },
                  {
                    icon: "bi-tools",
                    title: "Easy to use Control Panel",
                    description: "Use our intuitive Control Panel to manage your domain name, configure email accounts, renew your domain name and buy more services."
                  },
                  {
                    icon: "bi-gear",
                    title: "Bulk Tools",
                    description: "Easy-to-use bulk tools to help you Register, Renew, Transfer and make other changes to several Domain Names in a single step."
                  },
                ].map((item, index) => (
                  <div className="col-lg-6 col-md-6 col-12" key={index}>
                    <div className="style-one-card align-items-start">
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className={`bi ${item.icon}`} viewBox="0 0 16 16">
                          {/* SVG path can be added here based on the icon */}
                        </svg>
                      </div>
                      <div className="theme-section-heading">
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                      </ div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className="theme-section-gap">
            <div className="container">
              <div className="row align-items-center justify-content-between gy-4 gy-lg-0">
                <div className="col-12 col-lg-6">
                  <div className="theme-section-heading">
                    <h2 className="mt-3">Quick And Easy To <span className="highlight">Setup</span></h2>
                    <p>No gurus needed here! Easily setup your website on our high-speed private cloud without any previous or technical knowledge required. Manage all of your content, emails, and domains right inside of our platform. Build dynamic online stores secured by free and automatic SSL that rank higher in search engines!</p>
                    <a href="linux-hosting" className="read-more-btn mt-3 d-block"> Learn More <i className="ri-arrow-right-line" /> </a>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div className="feature_image">
                    <img src={feature} alt="Setup Feature" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="theme-section-gap">
            <div className="container">
              <div className="row align-items-center flex-lg-row-reverse justify-content-between gy-4 gy-lg-0">
                <div className="col-12 col-lg-6">
                  <div className="theme-section-heading">
                    <h2 className="mt-3">Your Website 20x <span className="highlight">Faster</span></h2>
                    <p>People Don't Visit Slow Websites. Recall the last slow website you visited. How would you describe that experience? A slow website is not only annoying but damaging to your search engine results and reputation. We're committed to delivering blazing fast load times for websites of all sizes!</p>
                    <a href="linux-hosting" className="read-more-btn mt-3 d-block"> Learn More <i className="ri-arrow-right-line" /> </a>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div className="feature_image bg2">
                    <img src={performance} alt="Performance Feature" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="theme-section-gap customer-support">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-12">
                  <div className="bg2 customer-support-box">
                    <div className="row justify-content-between g-5">
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="theme-section-heading text-start">
                          <h6 className="style">Contact Us</h6>
                          <h2 className="mt-3">Any query? <span className="highlight">let's talk</span></h2>
                          <p>Any questions or topics you'd like to discuss? Feel free to share your thoughts, and let's engage in a conversation. I'm here to help and chat with you.</p>
                          <a className="theme-btn two mt-3" href="https://manage.hostingaviator.cloud/contact.php">Support Center</a>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="customer-support-text text-center">
                          <div>
                            <span className="icon"><i className="bi bi-envelope-at-fill" /></span>
                          </div>
                          <div className="text-start cus-email">
                            <span className="lable">Email us</span>
                            <a href="mailto:support@hostingaviator.cloud">support@hostingaviator.cloud</a>
                          </div>
                          <div className="icon-2"><i className="bi bi-arrow-right-circle-fill" /></div>
                        </div>
                        <div className="customer-support-text text-center">
                          <div>
                            <span className="icon"><i className="bi bi-phone-flip" /></span>
                          </div>
                          <div className="text-start cus-email">
                            <span className="lable">Call</span>
                            <a href="tel:9696626959">+91 9696626959</a>
                          </div>
                          <div className="icon-2"><i className="bi bi-arrow-right-circle-fill" /></div>
                        </div>
                        
                        <div className="customer-support-text text-center">
                          <div>
                            <span className="icon"><i className="bi bi-geo-alt" /></span>
                          </div>
                          <div className="text-start cus-email">
                            <span className="lable">Our location</span>
                            <a target="_blank" href="#">Open Google Maps</a>
                          </div>
                          <div className="icon-2"><i className="bi bi-arrow-right-circle-fill" /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          

          <section className="faq theme-section-gap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="theme-section-heading text-center mb-4">
              <h2>Frequently <b className="highlight heading">Asked </b> Questions</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <div className="faq-qa">
                <div className="accordion" id="accordionExample">
                  {[ 
                    { question: "What is website hosting?", answer: "Website hosting is a service that allows individuals or organizations to make their website accessible on the internet. It involves storing website files and data on a server that is connected to the internet, making it accessible to users worldwide." },
                    { question: "What types of website hosting are available?", answer: "There are several types of website hosting, including shared hosting, VPS (Virtual Private Server) hosting, dedicated hosting, cloud hosting, and managed WordPress hosting. Each type has its own features, benefits, and pricing." },
                    { question: "What is shared hosting?", answer: "Shared hosting involves multiple websites sharing resources on a single server. It's a cost-effective option for small websites but may have limitations in terms of performance and customization." },
                    { question: "What is VPS hosting?", answer: "VPS hosting provides a virtual private server with dedicated resources for your website. It offers better performance and customization options compared to shared hosting." },
                    { question: "What is dedicated hosting?", answer: "Dedicated hosting means you have an entire server exclusively for your website. It provides the highest level of control, performance, and security but is also the most expensive option." },
                    { question: "What is cloud hosting?", answer: "Cloud hosting uses a network of virtual servers that can scale resources as needed. It offers flexibility, scalability, and high uptime, making it a popular choice for many websites." },
                    { question: "What is managed hosting?", answer: "Managed hosting services typically include server management, security updates, and technical support, allowing website owners to focus on content and business rather than server maintenance." }
                  ].map((item, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={index === 0} aria-controls={`collapse${index}`}>
                          {item.question}
                        </button>
                      </h2>
                      <div id={`collapse${index}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">{item.answer}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        </main>

        <footer>
          <div className="container">
            <div className="footer-services">
              <div className="row g-3">
                <div className="col-12">
                  <div className="service-contain">
                    <div className="service-box">
                      <div className="img">
                        <img src={customerImg} alt="Customer Support" />
                      </div>
                      <div className="content">
                        <h5>24 x 7 Customer Support</h5>
                      </div>
                    </div>
                    <div className="service-box">
                      <div className="img">
                        <img src={guaranteeImg} alt="Uptime Guarantee" />
                      </div>
                      <div className="content">
                        <h5>99% Uptime Guarantee</h5>
                      </div>
                    </div>
                    <div className="service-box">
                      <div className="img">
                        <img src={discountImg} alt="Promotions" />
                      </div>
                      <div className="content">
                        <h5>100+ Running Promos</h5>
                      </div>
                    </div>
                    <div className="service-box">
                      <div className="img">
                        <img src={moneyImg} alt="Money-back Guarantee" />
                      </div>
                      <div className="content">
                        <h5>07-day Money-back guarantee</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default HomePage;